/* eslint-disable @typescript-eslint/no-explicit-any */
import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import avatar from 'assets/img/team/72x72/avatar.webp';
import FeatherIcon from 'feather-icons-react';
import { Link, NavLink } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { logout } from '../../../redux/features/authSlice';
import { initialSessionClose } from '../../../redux/features/sessionClose';
import { Routes } from 'common/router-name';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(state => state.auth.user);
  const sessionData = useAppSelector(state => state.sessionClose.urls);
  const { UpcommingFeature, Profile, Dashboard_Two, MembersRoute, HelpCenter } =
    Routes;
  const [navItems] = useState([
    {
      label: 'Profile',
      icon: 'user',
      pathName: Profile
    },
    {
      label: 'Dashboard',
      icon: 'pie-chart',
      pathName: Dashboard_Two
    },
    // {
    //   label: 'Posts & Activity',
    //   icon: 'lock',
    //   pathName: UpcommingFeature
    // },
    // {
    //   label: 'Settings & Privacy ',
    //   icon: 'settings',
    //   pathName: UpcommingFeature
    // },
    {
      label: 'Help Center',
      icon: 'help-circle',
      pathName: HelpCenter,
      target: '_blank'
    }
    // {
    //   label: 'Language',
    //   icon: 'globe',
    //   pathName: UpcommingFeature
    // }
  ]);

  const closeChildTabs = () => {
    sessionData.forEach((childWindow: any) => {
      if (childWindow && !childWindow.closed) {
        childWindow?.close?.();
      }
    });
    dispatch(initialSessionClose());
  };

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={authUser.profile_image ?? avatar} size="xl" />
            <h6 className="text-body-emphasis">
              {authUser?.first_name ?? ''} {authUser?.last_name ?? ''}
            </h6>
          </div>
          <div style={{ height: 'auto', maxHeight: '10rem' }}>
            <Nav className="nav flex-column mb-2 pb-1">
              {navItems.map(item => (
                <Nav.Item key={item.label}>
                  <NavLink
                    to={item.pathName}
                    target={item.target}
                    className="px-3 nav-link"
                  >
                    <FeatherIcon
                      icon={item.icon}
                      size={16}
                      className="me-2 text-body"
                    />
                    <span className="text-body-highlight">{item.label}</span>
                  </NavLink>
                </Nav.Item>
              ))}
            </Nav>
          </div>
        </Card.Body>
        <Card.Footer
          className={`p-0 ${
            authUser.user_type === 'DA'
              ? 'border-top border-translucent'
              : 'border-0'
          }`}
        >
          {authUser.user_type === 'DA' && (
            <>
              <Nav className="nav flex-column my-3 mb-0">
                <Nav.Item>
                  <NavLink to={MembersRoute} className="px-3 nav-link">
                    <FeatherIcon
                      icon="user-plus"
                      size={16}
                      className="me-2 text-body"
                    />
                    <span className="text-body-highlight">
                      Add another account
                    </span>
                  </NavLink>
                </Nav.Item>
              </Nav>
              <hr />
            </>
          )}
          <div className="px-3 pt-3 border-top">
            <Button
              onClick={() => {
                // logoutToCloseChildTabs(tabRefs);
                // tabRefs.current.forEach((tab: any) => {
                //   if (tab && !tab.closed) {
                //     tab.close();
                //   }
                // });
                // tabRefs.current = [];
                closeChildTabs();
                dispatch(logout());
              }}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Logout
            </Button>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            <Link
              className="text-body-quaternary me-1"
              to="https://www.crimesly.com/privacy-policy"
              target="_blank"
            >
              Privacy policy
            </Link>
            •
            <Link
              className="text-body-quaternary mx-1"
              to="https://www.crimesly.com/privacy-policy"
              target="_blank"
            >
              Terms
            </Link>
            {/* •
            <Link className="text-body-quaternary ms-1" to={UpcommingFeature}>
              Cookies
            </Link> */}
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
